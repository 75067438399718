//トップページ　キービジュアルスライダー
import {
    no_scroll,
    return_scroll
} from '../common/_common';

export function roomManegeFunc() {
    let roomManege = $('#roomManege').length;
    let csvImport = $('#csvImport').length;

    if (roomManege) {
        const ele = document.getElementsByClassName("barCodeModal");
        let array_form = [];
        let array_btn = [];
        let array_btnBox = [];
        let array_select = [];
        let array_status = [];
        let array_resultTxt = [];
        let array_close = [];
        let array_modal = [];
        let alertTxt;

        for(let i = 0; i < ele.length; i++){
            let num = i+1;
            array_form.push( document.getElementById('form' + num));
            array_btn.push( document.getElementById('btn' + num));
            array_btnBox.push( document.getElementById('btnBox' + num));
            array_select.push(document.getElementById('roomManege' + num));
            array_status.push(document.getElementById('status' + num));
            array_resultTxt.push( document.getElementById('resultTxt' + num));
            array_close.push( document.getElementById('close' + num));
            array_modal.push( document.getElementById('modal' + num));

            if(array_resultTxt[i].dataset.value != ''){
                array_resultTxt[i].classList.add('isShow');
                array_select[i].value = array_resultTxt[i].dataset.value;
                array_btn[i].disabled = false;
                array_btnBox[i].classList.remove('disabled');
                array_btnBox[i].classList.add('active');
                array_select[i].classList.add('isShow');
            }
            if(array_resultTxt[i].dataset.value == "未選択"){
                array_resultTxt[i].classList.remove('isShow');
                array_select[i].classList.remove('isShow');
            }
            if(array_resultTxt[i].dataset.value == "空き"){
                array_resultTxt[i].classList.remove('isShow');
            }
            if(array_resultTxt[i].dataset.value == "空き" || array_resultTxt[i].dataset.value == "未選択"){
                array_btnBox[i].classList.remove('active');
                // array_resultTxt[i].classList.remove('isShow');
                // array_btn[i].disabled = true;
                // array_btnBox[i].classList.add('disabled');
            }

            array_select[i].onchange = event => { 
                array_btn[i].disabled = false;
                array_btnBox[i].classList.remove('disabled');
                //array_resultTxt[i].textContent = '現在' + event.target.value + 'が使用中です。';

                if(array_resultTxt[i].dataset.value == "空き" || array_resultTxt[i].dataset.value == '' || array_resultTxt[i].dataset.value == '未選択'){
                    alertTxt = 'オフィスを使用しますか？';
                } else {
                    alertTxt = 'オフィス使用者を変更しますか？';
                }

                if(array_resultTxt[i].dataset.value == '' && event.target.value != "空き"){
                    if (confirm(alertTxt)) {
                        array_resultTxt[i].dataset.value = event.target.value;
                        array_status[i].value = '使用者切換'; 
                        document.form[i].submit();
                    } else {
                        event.target.value = array_resultTxt[i].dataset.value;
                        //array_resultTxt[i].textContent = '現在' + array_resultTxt[i].dataset.value + 'が使用中です。';

                        if(array_resultTxt[i].dataset.value == ''){
                            event.target.value = '名前を選択';
                            array_btn[i].disabled = true;
                            array_btnBox[i].classList.add('disabled');
                            array_select[i].classList.remove('isShow');
                        }
                    }
                } else if(array_resultTxt[i].dataset.value == '' && event.target.value == "空き"){
                    array_resultTxt[i].dataset.value = event.target.value;
                    array_status[i].value = '使用者切換'; 
                    document.form[i].submit();
                }
                if(array_resultTxt[i].dataset.value != event.target.value && array_resultTxt[i].dataset.value != ''){
                    if (confirm(alertTxt)) {
                        array_resultTxt[i].dataset.value = event.target.value;
                        array_status[i].value = '使用者切換'; 
                        document.form[i].submit();
                    } else {
                        event.target.value = array_resultTxt[i].dataset.value;
                        //array_resultTxt[i].textContent = '現在' + array_resultTxt[i].dataset.value + 'が使用中です。';
                    }
                }
                if(event.target.value == "空き" || event.target.value == "未選択"){
                    //array_resultTxt[i].classList.remove('isShow');
                    array_btnBox[i].classList.remove('active');
                    //array_btn[i].disabled = true;
                    //array_btnBox[i].classList.add('disabled');
                }
            } 

            function modalFunc() {
                if (array_modal[i].classList.contains('isShow')) {
                  return_scroll();
                  array_modal[i].classList.remove('isShow');
                  array_resultTxt[i].classList.add('isShow');
                  array_btnBox[i].classList.add('active');
                  array_resultTxt[i].dataset.value = array_select[i].value;
                  document.form[i].submit();
                } else {
                  no_scroll();
                  array_modal[i].classList.add('isShow');
                  array_status[i].value = 'コード表示'; 
                }
            }
            array_btn[i].addEventListener('click', modalFunc, false);
            array_close[i].addEventListener('click', modalFunc, false);
        };

        
        if(csvImport){
            let csvSelect = document.getElementById('month');
            let csvBtn = document.getElementById('csvBtn');
            let csvBtnBox = document.getElementById('csvBtnBox');
            csvSelect.onchange = event => { 
                csvSelect.classList.add('isShow');
                csvBtn.disabled = false;
                csvBtnBox.classList.remove('disabled');
            }
        }
    }
}